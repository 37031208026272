<template>
	<div class="thumbnail-enabled position-relative">
		<div class="form-preview-container">
			<div class="px-0 page-content">
				<div class="el-container text-center">
					<img :src="$assetURL(images[currentPage - 1])" style="max-width: 100%" />
				</div>
			</div>
			<div class="preview-thumbnails border-e">
				<div class="text-center close-thumb-btn d-none">
					<v-avatar
						class="cursor-pointer"
						color="black"
						text-color="white"
						size="30"
						@click="isThumbnail = false"
					>
						<v-icon size="24" color="white">mdi-close</v-icon>
					</v-avatar>
				</div>
				<v-tabs v-model="fileDetailTab" class="file-tab w-100" grow>
					<v-tab href="#fileImg"><v-icon small left>mdi-file</v-icon>File</v-tab>
					<v-tab href="#detail"><v-icon small left>mdi-information-outline</v-icon>Info</v-tab>
				</v-tabs>

				<v-tabs-items v-model="fileDetailTab">
					<v-tab-item value="fileImg">
						<div class="thumbnails-inner-wrap">
							<div class="ma-2" v-for="(image, index) in images" :key="index">
								<img :src="$assetURL(image)" style="max-width: 100%" class="rounded border" />
							</div>
						</div>
					</v-tab-item>

					<v-tab-item value="detail">
						<div class="text-black px-2 py-2">
							<div class="text-center py-4">
								<v-avatar class="border" rounded size="100">
									<img :src="$assetURL(images[0])" />
								</v-avatar>
								<div class="fw-500 pt-2">{{ images[0].split("/").pop() }}</div>
							</div>
							<!-- <div class="grey lighten-4 px-2 text-h6 mb-2">Tags</div>
							<div>
								<v-chip
									v-for="(tag, index) in tags"
									:key="index"
									class="mb-1 px-1 mr-1 text-white"
									x-small
									:color="index % 2 === 0 ? 'blue' : 'green'"
									>{{ tag }}</v-chip
								>
							</div> -->
							<div class="grey lighten-4 px-2 text-h6 mt-3">Detail</div>
							<table width="100%">
								<tr>
									<td class="py-1 fw-500" width="100">File Size</td>
									<td class="py-1">{{ details.size }}</td>
								</tr>
								<!-- <tr>
									<td class="py-1 fw-500" width="100">Start</td>
									<td class="py-1"><v-icon small>mdi-calendar</v-icon>{{ details.start }}</td>
								</tr>
								<tr>
									<td class="py-1 fw-500" width="100">End</td>
									<td class="py-1"><v-icon small>mdi-calendar</v-icon>{{ details.end }}</td>
								</tr>
								<tr>
									<td class="py-1 fw-500" width="100">Reminder</td>
									<td class="py-1"><v-icon small>mdi-calendar</v-icon>{{ details.reminder }}</td>
								</tr> -->
							</table>
						</div>
					</v-tab-item>
				</v-tabs-items>
			</div>
			<div class="preview-pagination">
				<div class="pdf-pagination">
					<div class="left">
						<div class="btns">
							<div class="prev" @click="$emit('prevPage')">
								<v-tooltip left>
									<template v-slot:activator="{ on, attrs }">
										<v-icon v-bind="attrs" v-on="on">mdi-menu-left</v-icon>
									</template>
									<span>Previous Page</span>
								</v-tooltip>
							</div>
							<div class="number">
								<span>{{ currentPage }}</span>
							</div>
							<div class="next" @click="$emit('nextPage')">
								<v-tooltip right>
									<template v-slot:activator="{ on, attrs }">
										<v-icon v-bind="attrs" v-on="on">mdi-menu-right</v-icon>
									</template>
									<span>Next Page</span>
								</v-tooltip>
							</div>
						</div>
						<div class="txt">
							<span>of {{ totalPages }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		images: {
			type: Array,
			required: true,
		},
		details: {
			type: Object,
			required: true,
		},
		tags: {
			type: Array,
			required: true,
		},
		currentPage: {
			type: Number,
			required: true,
		},
		totalPages: {
			type: Number,
			required: true,
		},
	},
	data() {
		return {
			fileDetailTab: "fileImg",
		};
	},
};
</script>
<style scoped>
.form-preview-container {
	height: calc(100vh - 130px) !important;
	overflow: auto;
}

.preview-header {
	position: sticky;
	top: 0;
	z-index: 1;
	box-shadow: 0 0 15px #0d47a1;
	transition: 0.3s ease-in-out;
}

.scrolled .preview-header {
	box-shadow: 0px 5px 16px rgba(75, 74, 74, 0.2);
}

.thumbnail-enabled .page-content {
	margin-right: 250px !important;
}

.thumbnail-enabled .preview-pagination .pdf-pagination {
	transform: translateX(0%);
}

.preview-thumbnails {
	width: 250px;
	position: absolute;
	top: 0px;
	bottom: 0;
	right: 0;
	color: #fff;
	padding: 0px 12px 12px 0px;
	border-radius: 4px;
}

.preview-thumbnails .thumbnails-inner-wrap {
	height: 100%;
	overflow: auto;
}

.preview-thumbnails .close-thumb-btn {
	position: absolute;
	top: 8px;
	left: 50%;
	transform: translate(-50%, -15px);
	visibility: hidden;
	opacity: 0;
	transition: 0.3s ease-in;
}

.preview-thumbnails:hover .close-thumb-btn {
	transform: translate(-50%, 0);
	visibility: visible;
	opacity: 1;
}

.preview-pagination .pdf-pagination {
	display: flex;
	position: absolute;
	bottom: 150px;
	right: 50%;
	transform: translateX(-50%);
	color: #fff;
	padding: 0 6px;
	background: rgb(66, 66, 66);
	border-radius: 4px;
}

.preview-pagination .right {
	display: flex;
	padding: 6px 12px;
}

.preview-pagination .fit-to-width,
.preview-pagination .zoom-out,
.preview-pagination .zoom-in {
	width: 30px;
	height: 30px;
	margin: 0 3px;
	padding: 4px;
	color: rgb(255, 255, 255);
	cursor: pointer;
}

.preview-pagination .left {
	display: flex;
	min-width: 160px;
	padding: 6px 12px;
	align-items: center;
}

.preview-pagination .btns {
	display: flex;
	width: 100px;
}

.preview-pagination .btns > div {
	min-width: 24px;
	height: 24px;
}

.preview-pagination .prev {
	background: rgb(97, 97, 97);
	border-radius: 4px 0 0 4px;
	cursor: pointer;
}
.border-e {
	border-left: 1px solid rgb(182, 179, 179);
	border-radius: 0px;
}
.preview-pagination .next {
	background: grey;
	border-radius: 0 4px 4px 0;
	cursor: pointer;
}

.preview-pagination .txt {
	margin-left: 3px;
	font-weight: 500;
}

.preview-pagination .prev,
.preview-pagination .next {
	display: flex;
	justify-content: center;
	align-items: center;
}

.preview-pagination .prev img,
.preview-pagination .next img {
	height: 16px;
	width: 16px;
}

.preview-pagination .prev img {
	transform: rotate(90deg);
}

.preview-pagination .next img {
	transform: rotate(-90deg);
}

.preview-pagination .btns .number {
	width: 48px !important;
	height: 24px;
	background: rgb(97, 97, 97);
	margin: 0 1px;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
