<template>
	<div class="main-detail">
		<DetailTemplate :custom-class="'claims-detail detail-page section-main-conten px-0'">
			<template v-slot:header-topbar>
				<div :style="`border-bottom: 4px solid ${getStatusColor(claimData.status_value)}`">
					<div
						class="align-center px-4 py-2 flex-wrap"
						:class="`${getStatusColor(claimData.status_value)} lighten-5`"
					>
						<v-flex md12>
							<div class="d-flex align-center">
								<div>
									<span
										class="text-uppercase fw-700 text-h4 mb-1"
										v-if="claimData && claimData.billable_name"
										style="color: rgb(13, 71, 161)"
									>
										{{ claimData.billable_name }}
									</span>
									<template v-if="claimData.status == 1"
										><v-btn
											v-if="getPermission('claim:update') && currentUser.id == claimData.user"
											class="white--text ml-2 mb-1"
											depressed
											color="blue darken-4"
											tile
											v-on:click="routeToUpdate(claimId)"
										>
											Edit
										</v-btn></template
									>
									<br />
									<v-chip
										class="mr-1 text-capitalize"
										color="cyan white--text"
										small
										v-if="claimData && claimData.barcode"
										>{{ claimData.barcode }}
									</v-chip>
									<v-chip class="ml-1 mr-2" color="blue white--text" small v-if="claimData.claim_type">
										{{ claimData.claim_type }}
									</v-chip>
									<v-chip small>
										<v-avatar left>
											<img v-if="claimData.profile_img" :src="claimData.profile_img" />
											<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
										</v-avatar>

										{{ claimData.user_name }}
									</v-chip>
								</div>
								<v-spacer></v-spacer>
								<span class="me-2">
									<span class="fw-600 text-h3 green--text" v-if="claimData && claimData.amount">
										{{ formatMoney(claimData.amount) }}
									</span>
									<em class="text-muted" v-else> no amount</em>
								</span>
								<template v-if="claimData.status == 1 && false">
									<v-btn
										class="white--text mr-2"
										depressed
										color="green"
										tile
										@click="openApprovalDialog(2)"
										v-if="getPermission('claim:approve')"
									>
										Approve
									</v-btn>
									<v-btn
										class="white--text mr-2"
										depressed
										color="red"
										tile
										v-on:click="openApprovalDialog(3)"
										v-if="getPermission('claim:approve')"
									>
										Reject
									</v-btn>
								</template>
								<div class="fw-500 me-2">
									<span class="fw-500" v-if="claimData && claimData.expense_category">
										<v-tooltip top>
											<template v-slot:activator="{ on, attrs }">
												<v-chip
													v-bind="attrs"
													v-on="on"
													class="mb-1"
													label
													text-color=""
													color="blue white--text text-capitalize"
													small
												>
													{{ claimData.expense_category }}
												</v-chip>
											</template>
											<span>Expense Category</span>
										</v-tooltip>
									</span>
									<br />
									<span class="fw-500" v-if="claimData && claimData.sub_category">
										<v-tooltip top>
											<template v-slot:activator="{ on, attrs }">
												<v-chip
													v-bind="attrs"
													v-on="on"
													class="mr-0"
													label
													text-color=""
													outlined
													color="orange white--text text-capitalize"
													small
												>
													{{ claimData.sub_category }}
												</v-chip>
											</template>
											<span>Expense Sub Category</span>
										</v-tooltip>
									</span>
								</div>
								<div>
									<v-btn depressed tile class="mb-1" v-on:click="routeToListing()">
										<v-icon class="mr-1">mdi-keyboard-backspace</v-icon>
										back
									</v-btn>
									<p class="my-auto font-level-3-bold">
										<StatusInput
											v-if="claimData && claimData.status_value"
											v-model="claimData.status_value"
											:items="claimStatusList"
											@change="(value) => updateStatus(value, 'status')"
										/>
										<!-- <v-chip
											class="mb-0"
											:color="`${getStatusColor(claimData.status_value)} white--text`"
											medium
										>
											{{ claimData.status_value }}
										</v-chip> -->
									</p>
								</div>
							</div>
						</v-flex>
					</div>
				</div>
			</template>
			<template v-slot:sidebar-detail>
				<!-- Scrollable listing view -->
				<div class="listing-sideba">
					<div class="listing-header d-flex align-center py-1">
						<v-menu offset-y right bottom content-class="rounded-lg border elevation-3" rounded="0">
							<template #activator="{ on, attrs }">
								<div class="h5 m-0 d-flex align-center" v-on="on" v-bind="attrs">
									<v-icon class="px-0 mr-1" :color="currentStatusIcon[currentSideMenuStatus]?.color">{{
										currentStatusIcon[currentSideMenuStatus]?.icon
									}}</v-icon>
									<div class="mt-1">{{ currentStatusTitle[currentSideMenuStatus] }}</div>
									<!-- mdi-file-table mdi-progress-clock mdi-check-circle-outline mdi-close-circle-outline -->
									<v-icon class="mt-1">{{
										attrs && attrs["aria-expanded"] == "true" ? "mdi-menu-up" : "mdi-menu-down"
									}}</v-icon>
								</div>
							</template>

							<v-list>
								<v-list-item-group
									v-model="currentSideMenuStatus"
									@change="page == 1, getAllClaim('change')"
								>
									<v-list-item v-for="(status, index) in claimStatus" :key="index">
										<v-list-item-title class="d-flex align-items-center">
											{{ status.text }}
											<span class="circle" :class="status.status_color"></span>
											<v-spacer></v-spacer>
											<div class="cm-budge white--text" :class="status.status_color">
												{{ status.status_count }}
											</div>
										</v-list-item-title>
									</v-list-item>
								</v-list-item-group>
							</v-list>
						</v-menu>
						<v-spacer></v-spacer>
						<div class="my-1 fw-500 filterTagSelect">
							<AutoCompleteInput
								v-if="AllowAnyOforGetRoleNType(['admin', 'manager', 'super_admin'])"
								hide-details
								hideTopMargin
								item-text="display_name"
								item-value="id"
								placeholder="User"
								:items.sync="usersList"
								clearable
								noStyleList
								v-model="currentSideMenuUser"
								customClass="filterSelect"
								@change="getAllClaim"
								:inlineStyle="{ minWidth: '165px !important' }"
								:menu-props="{
									contentClass: 'autocomplete-menu-list',
								}"
							>
								<template #noStyleItem="{ li }">
									<div class="d-flex align-center justify-space-around">
										<v-avatar size="36" class="mr-2">
											<v-img v-if="li.profile_img" :src="li.profile_img"></v-img>
											<v-img v-else src="/media/users/blank.png"></v-img>
										</v-avatar>
										<div class="my-1">
											<div class="text-uppercase fw-600">{{ li.display_name }}</div>
											<div class="text-muted">{{ li.email }}</div>
										</div>
									</div>
								</template>
							</AutoCompleteInput>
							<div v-else class="mr-2 fw-600">
								Current Claims
								<v-chip color="primary" outlined class="ml-1">{{ filterData.length }}</v-chip>
							</div>
						</div>
					</div>

					<div class="sidebarsearch-wrapper" :class="search_open ? 'open' : ''">
						<div class="sidebarsearch">
							<v-text-field
								hide-details
								outlined
								placeholder="Search..."
								v-model="search"
								@keyup="searchClaim($event)"
							>
								<v-icon slot="append" @click="closeSerach"> mdi-close </v-icon>
							</v-text-field>
						</div>
					</div>
					<div class="side-listing listing-sidebar-list">
						<div class="listing-innerlist px-1 pt-1">
							<!-- <div v-if="pageLoading" class="text-center my-12">
								<v-progress-circular indeterminate color="#0d47a1"></v-progress-circular>
							</div> -->
							<div class="text-center my-12" v-if="!filterData.length">
								<em class="fw-600 text-muted">No Claims found</em>
							</div>
							<template v-else>
								<div
									class="list-items rounded-lg"
									v-for="(data, index) in filterData"
									:key="index"
									:class="`list-items ${data.id == claimId ? 'active' : ''}`"
									:style="`border: 1px solid ${getStatusColor(data.status)}`"
									@click="tabDetails(data.id)"
								>
									<!-- <div class="d-flex">
									<div class="ellipsis pr-6 w-60">
										<span class="fw-600 pr-2">Claim # : </span>
										<span class="fw-500" v-if="data && data.barcode">{{ data.barcode }}</span>
										<span v-else>no barcode</span>
									</div>
								</div> -->
									<div class="ellipsis pr-6 mb-1">
										<span class="fw-600 pr-2">Title : </span>
										<span class="fw-500" v-if="data && data.billable_name">{{ data.billable_name }}</span>
										<em class="text-muted" v-else>no title</em>
									</div>
									<div class="ellipsis pr-6">
										<span class="fw-600 pr-2">User : </span>
										<span class="fw-500 primary--text" v-if="data && data.user_name">
											<v-chip small>
												<v-avatar left>
													<img v-if="data.profile_img" :src="data.profile_img" />
													<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
												</v-avatar>

												{{ data.user_name }}
											</v-chip>
										</span>
										<em class="text-muted" v-else>no user name</em>
									</div>
									<div class="ellipsis pr-6" v-if="data.category == 'customer'">
										<span class="fw-600 pr-2">Company : </span>
										<span class="fw-500 primary--text" v-if="data && data.customer_company_name">{{
											data.customer_company_name
										}}</span>
									</div>
									<!-- <div class="ellipsis pr-6 mb-1">
									<span class="fw-600 pr-2">Shop Name : </span>
									<span class="fw-500" v-if="data && data.shop_name">{{ data.shop_name }}</span>
									<em class="text-muted" v-else>no shop name</em>
								</div> -->
									<div class="d-flex mb-1">
										<div>
											<span class="fw-600 pr-2">Amount : </span>
											<span class="fw-500 green--text">{{ formatMoney(data.amount) }}</span>
										</div>
										<v-spacer></v-spacer>
										<div v-if="data.status">
											<v-chip class="px-1 white--text" x-small label :color="getStatusColor(data.status)">
												<span class="fw-500">{{ data.status.charAt(0) }}</span>
											</v-chip>
										</div>
									</div>
									<div class="ellipsis pr-6 mb-1">
										<span class="fw-600 pr-2">Category : </span>
										<v-chip label color="blue white--text" x-small text-color="">
											<span class="fw-500" v-if="data && data.expense_category">{{
												data.expense_category.replace("-", " ")
											}}</span>
										</v-chip>
									</div>
									<!-- <v-tooltip top content-class="custom-top-tooltip">
									<template v-slot:activator="{ on, attrs }">
										<div class="pdf-download" v-on="on" v-bind="attrs">
											<v-icon link color="red">mdi-file-pdf-outline</v-icon>
										</div>
									</template>
									<span>Download</span>
								</v-tooltip> -->
									<!-- <v-checkbox hide-details class="ma-0 pa-0 size-16"> </v-checkbox> -->
								</div>
							</template>
						</div>
					</div>

					<div class="text-center my-2" v-if="showLoadMoreBtn">
						<v-btn
							color="primary"
							@click="
								() => {
									if (pageLoading) {
										return false;
									} else {
										loadMoreClaims();
									}
								}
							"
						>
							<template v-if="pageLoading">Loading...</template>
							<template v-else> <v-icon left>mdi-reload</v-icon>Load more </template>
						</v-btn>
					</div>
					<!-- customer details -->

					<!-- customer details end -->
				</div>
			</template>
			<template v-slot:body>
				<div class="p-sticky bg-white px-4 py-2">
					<v-tabs
						v-model="assetTab"
						background-color="transparent"
						color="white"
						class="custom-tab-transparent w-100"
						active-class="blue darken-4 text-white"
						hide-slider
					>
						<!-- <v-tab href="#file"><v-icon small left>mdi-file-document-multiple</v-icon>Files</v-tab> -->
						<v-tab href="#overview"><v-icon small left>mdi-information-outline</v-icon>Overview</v-tab>

						<v-tab v-if="false" href="#file"
							><v-icon small left>mdi-file-document-multiple</v-icon>Files</v-tab
						>
						<v-tab href="#activity" @click="claimActivity">
							<v-icon small left>mdi-history</v-icon>Activity
						</v-tab>
						<!--mdi-wrench-outline-->
					</v-tabs>
				</div>
				<div class="tab-layout-content px-0">
					<v-tabs-items v-model="assetTab">
						<v-tab-item value="overview">
							<v-row>
								<v-col md="5">
									<div class="detail-card bg-white">
										<div class="border mt-0">
											<div class="card--header d-flex align-items-center border-bottom pa-2">
												<div class="font-level-3-bold">Overview</div>
												<v-spacer></v-spacer>
											</div>
											<div class="card--body d-flex align-items-center pa-2">
												<v-row>
													<v-col md="12">
														<table>
															<!-- <tr>
																<td class="fw-600 px-3 py-2">Title</td>
																<td class="px-3 py-1">
																	<template v-if="claimData && claimData.billable_name">
																		<span>
																			{{ claimData.billable_name }}
																		</span>
																	</template>
																	<template v-else> <em class="text-muted">no title</em></template>
																</td>
															</tr> -->
															<tr>
																<td class="fw-600 px-3 py-2">Expense date</td>
																<td class="px-3 py-1">
																	<template v-if="claimData && claimData.expense_date">
																		{{ formatDate(claimData.expense_date) }}
																	</template>
																	<template v-else> <em class="text-muted">no expense date</em></template>
																</td>
															</tr>
															<tr>
																<td class="fw-600 px-3 py-2">Shop name</td>
																<td class="px-3 py-1">
																	<template v-if="claimData && claimData.shop_name">
																		<span>
																			{{ claimData.shop_name }}
																		</span>
																	</template>
																	<template v-else> <em class="text-muted">no shop name</em></template>
																</td>
															</tr>
															<tr>
																<td valign="top" class="fw-600 px-3 py-2">Meeting</td>
																<td class="px-3 py-1">
																	<template v-if="claimData && claimData.meeting_id">
																		<div class="">
																			{{ formatDate(claimData.meeting_start_date) }}
																			{{ claimData.meeting_start_time }} ({{ claimData.meeting_duration }})
																			<v-icon
																				size="20"
																				color="blue darken-4"
																				@click="goToDetail(claimData.meeting_id, 'meeting')"
																				>mdi-open-in-new</v-icon
																			>
																		</div>
																	</template>
																	<template v-else> <em class="text-muted">no meeting</em></template>
																</td>
															</tr>
															<tr>
																<td valign="top" class="fw-600 px-3 py-2">Payment Mode</td>
																<td class="px-3 py-1">
																	<template v-if="claimData && claimData.payment_mode">
																		<v-chip
																			color="cyan"
																			label
																			outlined
																			x-small
																			class="text-capitalize"
																			v-if="claimData && claimData.payment_mode && claimData.payment_mode != 'null'"
																			>{{ claimData.payment_mode.replace("-", " ") }}</v-chip
																		>
																	</template>
																	<template v-else> <em class="text-muted">no payment mode</em></template>
																</td>
															</tr>
															<tr>
																<td class="fw-600 px-3 py-2">Reference</td>
																<td class="px-3 py-1">
																	<template v-if="claimData && claimData.reference && claimData.reference != 'null'">
																		<span>
																			{{ claimData.reference }}
																		</span>
																	</template>
																	<template v-else> <em class="text-muted">no reference</em></template>
																</td>
															</tr>

															<!-- <tr>
																<td class="fw-600 px-3 py-2">Currency</td>
																<td class="px-3 py-1">
																	<template v-if="claimData && claimData.currency">
																		{{ claimData.currency }}
																	</template>
																	<template v-else> <em class="text-muted">no currency</em></template>
																</td>
															</tr> -->
															<tr>
																<td class="fw-600 px-3 py-2">Interval</td>
																<td class="px-3 py-1 text-capitalize">
																	<template v-if="claimData && claimData.duration_type">
																		{{ claimData.duration_type }}
																	</template>
																	<template v-else> <em class="text-muted">no interval</em></template>
																</td>
															</tr>
															<tr>
																<td class="fw-600 px-3 py-2">Created At</td>
																<td class="px-3 py-1">
																	<template v-if="claimData && claimData.added_at">
																		{{ formatDate(claimData.added_at) }}
																	</template>
																	<template v-else> <em class="text-muted">no created date</em></template>
																</td>
															</tr>
														</table>
													</v-col>
												</v-row>
											</div>
										</div>
										<v-row>
											<v-col md="12">
												<div class="border mt-3">
													<div
														class="card--header d-flex align-items-center border-bottom pa-2"
														@click="isShowCustDetail = !isShowCustDetail"
													>
														<div class="font-level-3-bold">Customer Detail</div>
														<v-spacer></v-spacer>
														<v-icon>{{ isShowCustDetail ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
													</div>
													<div class="card--body d-flex align-items-center pa-2" v-if="isShowCustDetail">
														<table>
															<tr>
																<td class="fw-600 px-3 py-2">Customer</td>
																<td class="px-3 py-1">
																	<template v-if="claimData && claimData.customer_company_name">
																		<span
																			@click="goToDetail(claimData.customer_id, 'customer')"
																			style="cursor: pointer"
																		>
																			{{ claimData.customer_company_name }}
																		</span>
																	</template>
																	<template v-else> <em class="text-muted">no customer</em></template>
																</td>
															</tr>
															<tr>
																<td class="fw-600 px-3 py-2">UEN</td>
																<td class="px-3 py-1">
																	<template v-if="claimData && claimData.customer_company_uen">
																		<span>
																			{{ claimData.customer_company_uen }}
																		</span>
																	</template>
																	<template v-else> <em class="text-muted">no uen</em></template>
																</td>
															</tr>
															<tr>
																<td class="fw-600 px-3 py-2">Website</td>
																<td class="px-3 py-1">
																	<template
																		v-if="claimData && claimData.customer_website && claimData.customer_website != ''"
																	>
																		<span>
																			{{ claimData.customer_website }}
																		</span>
																	</template>
																	<template v-else> <em class="text-muted">no website</em></template>
																</td>
															</tr>
														</table>
													</div>
												</div>
											</v-col>
											<v-col md="12">
												<div class="border mt-3">
													<div
														class="card--header d-flex align-items-center border-bottom pa-2"
														@click="isShowProjDetail = !isShowProjDetail"
													>
														<div class="font-level-3-bold">Project Detail</div>
														<v-spacer></v-spacer>
														<v-icon>{{ isShowProjDetail ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
													</div>
													<div class="card--body d-flex align-items-center pa-2" v-if="isShowProjDetail">
														<table>
															<tr>
																<td class="fw-600 px-3 py-2">Project#</td>
																<td class="px-3 py-1">
																	<template v-if="claimData && claimData.project_id">
																		<span
																			@click="goToDetail(claimData.project_id, 'project')"
																			style="cursor: pointer"
																		>
																			<v-chip
																				class="mr-1"
																				label
																				text-color=""
																				outlined
																				color="blue white--text text-capitalize"
																				small
																			>
																				{{ claimData.project_barcode }}
																			</v-chip>
																		</span>
																	</template>
																	<template v-else> <em class="text-muted">no project</em></template>
																</td>
															</tr>
															<tr>
																<td class="fw-600 px-3 py-2">Cost</td>
																<td class="px-3 py-1">
																	<template v-if="claimData && claimData.project_cost">
																		<v-chip
																			class="mr-1"
																			label
																			text-color=""
																			outlined
																			color="green white--text text-capitalize"
																			small
																		>
																			{{ formatMoney(claimData.project_cost) }}
																		</v-chip>
																	</template>
																	<template v-else> <em class="text-muted">no cost</em></template>
																</td>
															</tr>
															<tr>
																<td class="fw-600 px-3 py-2">Service Type</td>
																<td class="px-3 py-1">
																	<template v-if="claimData && claimData.project_service_type">
																		<template v-for="row in projectServiceType(claimData.project_service_type)">
																			<v-chip
																				class="mr-1 mt-1"
																				label
																				text-color=""
																				outlined
																				:key="row"
																				color="blue white--text text-capitalize"
																				small
																			>
																				{{ row }}
																			</v-chip>
																		</template>
																	</template>
																	<template v-else> <em class="text-muted">no Service</em></template>
																</td>
															</tr>
														</table>
													</div>
												</div>
											</v-col>
										</v-row>
										<div class="border mt-3">
											<div class="card--header d-flex align-items-center border-bottom pa-2">
												<div class="font-level-3-bold">Notes</div>
												<v-spacer></v-spacer>
											</div>
											<div class="card--body d-flex align-items-center pa-2">
												<template v-if="claimData && claimData.notes">
													{{ claimData.notes }}
												</template>
												<template v-else> <em>no notes</em></template>
											</div>
										</div>
										<div class="border mt-3" v-if="claimDataRemark && claimDataRemark.remark">
											<div class="card--header d-flex align-items-center border-bottom pa-2">
												<div class="font-level-3-bold">Remark</div>
												<v-spacer></v-spacer>
											</div>
											<div class="card--body d-flex align-items-center pa-2">
												<template v-if="claimDataRemark && claimDataRemark.remark">
													{{ claimDataRemark?.remark }}
												</template>
												<template v-else> <em>no remark</em></template>
											</div>
										</div>
									</div>
								</v-col>
								<v-col md="7">
									<div class="bg-white pb-2 file-tab">
										<v-tabs
											v-model="fileTab"
											background-color="transparent"
											color="white"
											class="custom-tab-transparent w-100"
											active-class="blue darken-4 text-white"
											hide-slider
											grow
										>
											<v-tab href="#file1"><v-icon small left>mdi-file</v-icon>File 1</v-tab>
											<v-tab href="#file2"><v-icon small left>mdi-file</v-icon>File 2</v-tab>
											<v-tab href="#file3"><v-icon small left>mdi-file</v-icon>File 3</v-tab>
										</v-tabs>
									</div>

									<v-tabs-items v-model="fileTab" class="border">
										<v-tab-item :value="'file1'">
											<FilePreview
												:images="files['file1'].images"
												:details="files['file1'].details"
												:tags="files['file1'].tags"
												:currentPage="currentPage['file1']"
												:totalPages="totalPages['file1']"
												@prevPage="prevPage"
												@nextPage="nextPage"
											/>
										</v-tab-item>
										<v-tab-item :value="'file2'">
											<FilePreview
												:images="files['file2'].images"
												:details="files['file2'].details"
												:tags="files['file2'].tags"
												:currentPage="currentPage['file2']"
												:totalPages="totalPages['file2']"
												@prevPage="prevPage"
												@nextPage="nextPage"
											/>
										</v-tab-item>
										<v-tab-item :value="'file3'">
											<FilePreview
												:images="files['file3'].images"
												:details="files['file3'].details"
												:tags="files['file3'].tags"
												:currentPage="currentPage['file3']"
												:totalPages="totalPages['file3']"
												@prevPage="prevPage"
												@nextPage="nextPage"
											/>
										</v-tab-item>
									</v-tabs-items>
								</v-col>
							</v-row>
						</v-tab-item>
						<v-tab-item value="file" v-if="false">
							<v-tab-item value="file">
								<Files
									v-if="assetTab == 'file'"
									class="mx-4"
									type="claim"
									:url="'claim'"
									:type-id="claimId"
								>
								</Files>
							</v-tab-item>
						</v-tab-item>
						<v-tab-item value="activity">
							<div class="py-3">
								<div class="fs-16 fw-500 primary--text">Activity</div>
							</div>
							<ActivityTabNew v-if="false" :logs="logData"></ActivityTabNew>
							<div v-for="(logs, index) in logData" :key="index">
								<ActivityTab :open_details="true" :log="logs" title="Claim"></ActivityTab>
							</div>
						</v-tab-item>
						<v-tab-item value="task">
							<div>
								<div class="py-3">
									<div class="fs-16 fw-500 primary--text">Upcoming</div>
								</div>
								<div v-for="(tasks, index) in taskData" :key="index">
									<TaskTab
										v-on:singleTaskEdit="taskEdit($event)"
										:open_details="!index ? true : false"
										:task="tasks"
										:index-task="index"
										v-on:taskSuccess="refreshTask"
										v-on:priorityChange="refreshTask"
									>
									</TaskTab>
								</div>
								<div></div>
							</div>
						</v-tab-item>
						<v-tab-item value="reminder">
							<div>
								<div class="py-3">
									<div class="fs-16 fw-500 primary--text">April 28</div>
								</div>
								<div v-for="(reminder, index) in reminderData" :key="index">
									<ReminderTab
										:open_details="!index ? true : false"
										:remind="reminder"
										:index-reminder="index"
										v-on:reminderSuccess="refreshReminder"
										v-on:editReminder="editReminderForm($event)"
									>
									</ReminderTab>
								</div>
							</div>
						</v-tab-item>
					</v-tabs-items>
				</div>
			</template>
		</DetailTemplate>
		<!-- sidebar drawers -->
		<ClaimApprovalDialog
			v-if="approvalDialog"
			:dialog="approvalDialog"
			:action-type="approvalActionType"
			:claim-id="claimData.id"
			:claim-data="claimData"
			@close="approvalDialog = false"
			@success="
				getSingleClaim();
				getAllClaim();
			"
		/>
	</div>
</template>

<script>
// import CreateReminder from "@/view/pages/leads/create/CreateReminder";
// import CreateTask from "@/view/pages/leads/create/CreateTask";
// import CreateMeeting from "@/view/pages/leads/create/CreateMeeting";
//import Attachments from "@/view/module/contacts/customer/components/Attachments";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import ActivityTab from "@/view/pages/leads/components/ActivityTab";
import TaskTab from "@/view/pages/leads/components/TaskTab";
import ReminderTab from "@/view/pages/leads/components/ReminderTab";
import { SET_MESSAGE } from "@/core/services/store/common.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { toSafeInteger } from "lodash";
import { GET, QUERY, PATCH } from "@/core/services/store/request.module";
import Files from "@/view/components/Files";
import StatusInput from "@/view/components/StatusInput.vue";
import ClaimApprovalDialog from "@/view/module/claims/ClaimApprovalDialog.vue";
import { getCurrentUser } from "@/core/services/jwt.service";
import objectPath from "object-path";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import FilePreview from "@/view/components/FilePreview.vue";

export default {
	name: "claims-details",
	data() {
		return {
			fileTab: "file1",
			fileDetailTab: "fileImg",
			currentPage: {
				file1: 1,
				file2: 1,
				file3: 1,
			},
			totalPages: {
				file1: 3,
				file2: 3,
				file3: 2,
			},
			// Details of each file
			files: {
				file1: {
					images: ["media/preview-img.jpg", "media/preview-img-2.jpg", "media/preview-img.jpg"],
					details: { size: "12 KB", start: "07/08/2024", end: "10/08/2024", reminder: "09/08/2024" },
					tags: ["Tags1", "Tags2"],
				},
				file2: {
					images: ["media/preview-img.jpg", "media/preview-img-2.jpg", "media/preview-img.jpg"],
					details: { size: "15 KB", start: "08/08/2024", end: "11/08/2024", reminder: "10/08/2024" },
					tags: ["Tags3", "Tags4"],
				},
				file3: {
					images: ["media/preview-img.jpg", "media/preview-img-2.jpg"],
					details: { size: "10 KB", start: "09/08/2024", end: "12/08/2024", reminder: "11/08/2024" },
					tags: ["Tags5", "Tags6"],
				},
			},

			approvalDialog: false,
			isShowCustDetail: false,
			isShowProjDetail: false,
			approvalActionType: "approved",
			attachmentData: [],
			filterData: [],
			logData: [],
			claimStatus: [],
			claimStatusList: [],
			taskData: [],
			editTaskData: {},
			editReminderData: {},
			reminderData: [],
			search: null,
			search_open: false,
			claimData: {},
			claimDataRemark: {},
			claimListData: [],
			open_dialog: {
				task: false,
				meeting: false,
				reminder: false,
			},
			panel: [0],
			drawerCreate: false,
			attachmentDetails: [],
			task_action: false,
			open_details: false,
			assign_note: "Add Description",
			file_list: [],
			page: 1,
			list1: [
				{
					name: "BIgger HVAC/compressor",
					category: "Software",
					amount: "$ 1600.00",
					date: "12/04/2022",
					customer_name: "sample",
				},
				{
					name: "BIgger HVAC/compressor",
					category: "Software",
					amount: "$ 1600.00",
					date: "12/04/2022",
					customer_name: "sample",
				},
				{
					name: "BIgger HVAC/compressor",
					category: "Software",
					amount: "$ 1600.00",
					date: "12/04/2022",
					customer_name: "sample",
				},
			],
			usersList: [],
			pageLoading: false,
			currentSideMenuStatus: "all",
			currentSideMenuUser: null,
			total_pages: 1,
			currentStatusTitle: {
				all: "All Claims",
				0: "All Claims",
				1: "Pending",
				2: "Approved",
				3: "Rejected",
			},
			currentStatusIcon: {
				all: { icon: "mdi-file-table", color: "blue" },
				0: { icon: "mdi-file-table", color: "blue" },
				1: { icon: "mdi-progress-clock", color: "orange" },
				2: { icon: "mdi-check-circle-outline", color: "green" },
				3: { icon: "mdi-close-circle-outline", color: "red" },
			},
		};
	},
	components: {
		ActivityTabNew: () => import("@/view/pages/leads/components/ActivityTabNew"),
		DetailTemplate,
		FilePreview,
		ActivityTab,
		TaskTab,
		ReminderTab,
		StatusInput,
		Files,
		ClaimApprovalDialog,
		AutoCompleteInput,
		// Attachments,
		// UploadFIle,
		// CreateTask,
		// CreateReminder,
		// CreateMeeting,
	},
	methods: {
		// Method to go to the previous page
		prevPage() {
			if (this.currentPage[this.fileTab] > 1) {
				this.currentPage[this.fileTab]--;
			}
		},
		// Method to go to the next page
		nextPage() {
			if (this.currentPage[this.fileTab] < this.totalPages[this.fileTab]) {
				this.currentPage[this.fileTab]++;
			}
		},
		/* showLoadMoreBtn() {
			if (this.allClaims && this.allClaims > 15 && this.allClaims > this.filterData.length) {
				return true;
			} else {
				return false;
			}
		}, */
		loadMoreClaims() {
			if (Math.ceil(this.allClaims / 15) > this.page) {
				this.page++;
				this.getAllClaim();
			}
		},
		openApprovalDialog(status) {
			this.approvalDialog = true;
			this.approvalActionType = status;
			//console.log(this.approvalDialog);
		},
		projectServiceType(data) {
			return data.split(",");
		},
		goToDetail(id, type = "") {
			if (!type) {
				return false;
			}
			let modulePath = "";
			switch (type) {
				case "customer":
					modulePath = "customer-detail2";
					break;
				case "project":
					modulePath = "project-detail";
					break;
				case "meeting":
					modulePath = "meeting-detail";
					break;
			}
			this.$router.push({
				name: `${modulePath}`,
				params: {
					id: id,
				},
			});
		},
		routeToUpdate(id) {
			this.$router.push({
				name: "update-claim",
				params: { id },
				query: { ...this.$route.query, t: new Date().getTime() },
			});
		},
		routeToListing() {
			let query = this.$route.query;
			this.$router.push({
				name: "claims",
				query: {
					...query,
					page: query.page,
					t: new Date().getTime(),
				},
			});
		},
		copyNumber(data) {
			navigator.clipboard.writeText(data);
			this.$store.commit(SET_MESSAGE, [
				{ model: true, message: `<b>${data}</b> - successfully coppied.` },
			]);
		},
		getStatusText() {
			if (this.claimData.status == 1) {
				return "Pending";
			}
			if (this.claimData.status == 2) {
				return "Approved";
			}
			if (this.claimData.status == 3) {
				return "Rejected";
			}
		},
		getcategoryColor(cate) {
			if (cate == "owan") {
				return "red  white--text";
			}
			if (cate == "customer") {
				return "blue white--text";
			}
		},
		getStatusColor(status) {
			if (status == "Pending") {
				return "orange";
			}
			if (status == "Approved") {
				return "green";
			}
			if (status == "Rejected") {
				return "red";
			}
			return "cyan";
		},
		pageTitle() {
			return "Claims Details";
		},
		remove_file(index) {
			this.file_list.splice(index, 1);
		},
		closeSerach() {
			this.search_open = false;
			this.search = "";
		},
		refreshTask() {
			this.getTasks();
		},
		refreshReminder() {
			this.getReminder();
		},
		claimActivity() {
			this.$store
				.dispatch(GET, { url: `claim-activity/${this.claimId}` })
				.then((data) => {
					this.logData = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		editReminderForm(id) {
			this.open_dialog.reminder = true;
			this.$store
				.dispatch(GET, { url: `single-reminder/${id}` })
				.then((data) => {
					this.editReminderData = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		getReminder() {
			this.$store
				.dispatch(QUERY, {
					url: `reminder`,
					data: {
						related_id: this.claimId,
						related_to: "claim",
					},
				})
				.then((data) => {
					this.reminderData = data.rows;
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		getTasks() {
			this.$store
				.dispatch(QUERY, {
					url: `task`,
					data: {
						related_id: this.claimId,
						related_to: "claim",
					},
				})
				.then((data) => {
					this.taskData = data.rows;
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		taskEdit(id) {
			this.open_dialog.task = true;
			this.$store
				.dispatch(GET, {
					url: `single-task/${id}`,
				})
				.then((data) => {
					this.editTaskData = data;
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		getSingleClaim() {
			this.$store
				.dispatch(GET, {
					url: `single-claim/${this.claimId}`,
				})
				.then((data) => {
					this.claimData = data.claim;
					this.attachmentData = data.attachment;
					this.claimDataRemark = data.remark;
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		getAllClaim(type = null) {
			this.pageLoading = true;
			if (type) this.page = 1;
			const PAYLOAD = {
				page: this.page,
				status: this.currentSideMenuStatus,
				user_filter: this.currentSideMenuUser,
			};
			this.$store
				.dispatch(QUERY, {
					url: `claim`,
					data: PAYLOAD,
				})
				.then((data) => {
					this.claimStatus = data.status;
					this.total_pages = data.total_page;
					if (this.page == 1 || type == "change") {
						this.filterData = [...data.tbody];
					} else {
						this.filterData = [...this.filterData, ...data.tbody];
					}
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		updateStatus(status) {
			let _action = "";
			switch (status) {
				case "Pending":
					_action = "mark_claim_pending";
					break;
				case "Approved":
					_action = "mark_claim_approved";
					break;
				case "Rejected":
					_action = "mark_claim_rejected";
					break;
			}
			const payload = { action: _action, selected: [this.claimData.id] };
			this.$store
				.dispatch(PATCH, {
					url: "claim/bulk-action",
					data: payload,
				})
				.then(() => {
					this.getSingleClaim();
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		claimSetting() {
			this.$store
				.dispatch(GET, {
					url: `claim-setting`,
				})
				.then((data) => {
					/* this.claimStatus = data.status; */
					let _status = [];
					data.status.forEach((row) => {
						if (row.value != "all") {
							_status.push({ text: row.text, value: row.text, status_color: row.status_color });
						}
					});
					this.claimStatusList = _status;
					this.usersList = data.users.filter((user) => user.users_type == "sales") || [];
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		statusFilterData() {
			this.$store
				.dispatch(QUERY, {
					url: `claim`,
					data: {
						status: this.currentSideMenuStatus,
						user_filter: this.currentSideMenuUser,
					},
				})
				.then((data) => {
					if (data) {
						this.filterData = data.tbody;
					}
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		searchClaim($event) {
			const keySearch = $event.target.value;

			if (keySearch !== "") {
				this.$store
					.dispatch(QUERY, {
						url: `claim`,
						data: {
							search: keySearch,
						},
					})
					.then((data) => {
						this.filterData = data.tbody;
					})
					.catch((error) => {
						console.log({
							error,
						});
					});
			}
		},
		tabDetails(id) {
			this.$router.push({
				name: "claims-details",
				params: {
					id,
				},
				query: { ...this.$route.query, t: new Date().getTime() },
			});
			this.claimId = id;
			this.getSingleClaim();
			this.getTasks();
			this.getReminder();
			this.claimActivity();
		},
		editClaim(id) {
			this.$router.push({
				name: "update-claim",
				params: {
					id,
				},
				query: { ...this.$route.query, t: new Date().getTime() },
			});
		},
	},
	computed: {
		// // Computed property to get the current file's images
		// currentImages() {
		// 	return this.files[this.fileTab].images;
		// },
		// // Computed property to get the current file's details
		// currentDetails() {
		// 	return this.files[this.fileTab].details;
		// },
		// // Computed property to get the current file's tags
		// currentTags() {
		// 	return this.files[this.fileTab].tags;
		// },
		// // Computed property to get the current page's image
		// getCurrentPageImage() {
		// 	return this.currentImages[this.currentPage[this.fileTab] - 1];
		// },
		assetTab: {
			set(val) {
				let query = { ...this.$route.query };
				query.tab = val;
				if (val != this.assetTab) {
					this.$router.replace({ query });
				}
			},
			get() {
				return this.$route.query.tab || "claim";
			},
		},
		allClaims: {
			get() {
				return parseInt(
					objectPath.get(
						this.claimStatus,
						`${this.currentSideMenuStatus == "all" ? 0 : this.currentSideMenuStatus}.status_count`
					)
				);
			},
		},
		showLoadMoreBtn: {
			get() {
				if (this.allClaims && this.allClaims > 15 && this.allClaims > this.filterData.length) {
					return true;
				} else {
					return false;
				}
			},
		},
	},
	mounted() {
		if (!this.getPermission("claim:view") && this.claimId > 0) {
			this.$router.replace({
				name: "claims",
				query: { t: new Date().getTime() },
			});
		}

		this.getSingleClaim();
		this.getAllClaim();
		this.claimSetting();
		this.getTasks();
		this.getReminder();
		this.claimActivity();

		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Claims", disabled: false, href: "/claims" },
			// { text: "Claims", disabled: false, href: "/claims" },
			{ text: "Detail", disabled: true },
		]);

		this.currentUser = getCurrentUser();
	},
	beforeMount() {
		this.claimId = toSafeInteger(this.$route.params.id);
	},
};
</script>
<style scoped>
.form-preview-container {
	height: calc(100vh - 130px) !important;
	overflow: auto;
}

.preview-header {
	position: sticky;
	top: 0;
	z-index: 1;
	box-shadow: 0 0 15px #0d47a1;
	transition: 0.3s ease-in-out;
}

.scrolled .preview-header {
	box-shadow: 0px 5px 16px rgba(75, 74, 74, 0.2);
}

.thumbnail-enabled .page-content {
	margin-right: 250px !important;
}

.thumbnail-enabled .preview-pagination .pdf-pagination {
	transform: translateX(0%);
}

.preview-thumbnails {
	width: 250px;
	position: absolute;
	top: 0px;
	bottom: 0;
	right: 0;
	color: #fff;
	padding: 0px 12px 12px 0px;
	border-radius: 4px;
}

.preview-thumbnails .thumbnails-inner-wrap {
	height: 100%;
	overflow: auto;
}

.preview-thumbnails .close-thumb-btn {
	position: absolute;
	top: 8px;
	left: 50%;
	transform: translate(-50%, -15px);
	visibility: hidden;
	opacity: 0;
	transition: 0.3s ease-in;
}

.preview-thumbnails:hover .close-thumb-btn {
	transform: translate(-50%, 0);
	visibility: visible;
	opacity: 1;
}

.preview-pagination .pdf-pagination {
	display: flex;
	position: absolute;
	bottom: 150px;
	right: 50%;
	transform: translateX(-50%);
	color: #fff;
	padding: 0 6px;
	background: rgb(66, 66, 66);
	border-radius: 4px;
}

.preview-pagination .right {
	display: flex;
	padding: 6px 12px;
}

.preview-pagination .fit-to-width,
.preview-pagination .zoom-out,
.preview-pagination .zoom-in {
	width: 30px;
	height: 30px;
	margin: 0 3px;
	padding: 4px;
	color: rgb(255, 255, 255);
	cursor: pointer;
}

.preview-pagination .left {
	display: flex;
	min-width: 160px;
	padding: 6px 12px;
	align-items: center;
}

.preview-pagination .btns {
	display: flex;
	width: 100px;
}

.preview-pagination .btns > div {
	min-width: 24px;
	height: 24px;
}

.preview-pagination .prev {
	background: rgb(97, 97, 97);
	border-radius: 4px 0 0 4px;
	cursor: pointer;
}
.border-e {
	border-left: 1px solid rgb(182, 179, 179);
	border-radius: 0px;
}
.preview-pagination .next {
	background: grey;
	border-radius: 0 4px 4px 0;
	cursor: pointer;
}

.preview-pagination .txt {
	margin-left: 3px;
	font-weight: 500;
}

.preview-pagination .prev,
.preview-pagination .next {
	display: flex;
	justify-content: center;
	align-items: center;
}

.preview-pagination .prev img,
.preview-pagination .next img {
	height: 16px;
	width: 16px;
}

.preview-pagination .prev img {
	transform: rotate(90deg);
}

.preview-pagination .next img {
	transform: rotate(-90deg);
}

.preview-pagination .btns .number {
	width: 48px !important;
	height: 24px;
	background: rgb(97, 97, 97);
	margin: 0 1px;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
